
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import swipe from '@/directives/swipe';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import SliderBullets from '@/components/common/SliderBullets.vue';
    import SliderNavBtns from '@/components/common/SliderNavBtns.vue';
    import striptags from 'striptags';

    @Component({
        components: {
            ImageComponent,
            SliderWrapper,
            SliderBullets,
            SliderNavBtns
        },
        directives: { swipe }
    })
    export default class BaseSlider extends Vue {
        @Prop() items: any[];
        @Prop({ default: 0 }) startFrom: number;
        @Prop() additionalComponent: any[];

        striptags = striptags;
    }
