
    import { Component, Prop, namespace } from 'nuxt-property-decorator';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Menu from '@/components/blocks/menu/Menu.vue';
    import MobileMenu from '@/components/blocks/menu/MobileMenu.vue';
    import DetectScrollDirectionMixin from '@/mixins/detectScrollDirectionMixin';

    const user = namespace('user');

    @Component({
        components: { MobileMenu, Menu, OptionsFieldsInlineEditor }
    })
    export default class Header extends DetectScrollDirectionMixin {
        @Prop() component;
        @Prop() logo;
        @Prop() logoImage;
        @Prop() logoHref;
        @Prop() items;
        @Prop() phone;
        @Prop() text;
        @Prop() form;

        @user.State auth;

        mounted() {
            this.detectScrollDirection();
        }

        lockScroll() {
            // @ts-ignore
            document.documentElement.classList.toggle('no-scroll');
            // @ts-ignore
            document.body.classList.toggle('no-scroll');
        }
    }
